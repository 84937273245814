import { AxiosInstance } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { FT_PAYMENT_ACTIVITY, FT_REQUEST_BASE_URL } from "constants/request";
import {
  FetchPaymentActivityProps,
  constructPaymentActivityUrl,
} from "store/paymentActivity/paymentActivity-action";
import { FetchPayloadResult } from "store/paymentActivity/paymentActivity-slice";

export type FetchReciepts = typeof fetchReciepts;

export const fetchReciepts = createAsyncThunk<
  FetchPayloadResult,
  {
    request: AxiosInstance;
    data: FetchPaymentActivityProps;
  }
>("receipts/fetchReciepts", async ({ request, data }) => {
  let paymentActivityUrl = new URL(FT_PAYMENT_ACTIVITY, FT_REQUEST_BASE_URL);
  paymentActivityUrl = constructPaymentActivityUrl(data, paymentActivityUrl);

  const response = await request(paymentActivityUrl.toString(), {
    method: "GET",
  });
  return response.data;
});
