import React from "react";

import classes from "./ItemBar.module.scss";

export interface BarItems {
  title: string;
  isSelected?: boolean;
  isVisited?: boolean;
  icon: string;
}
interface QuickPayBarProps {
  progress: string;
  items: BarItems[];
}

export const QuickPayBar: React.FC<QuickPayBarProps> = ({
  progress,
  items,
}) => {
  const getClass = (item: any) => {
    if (item.isSelected) {
      return classes.Qpay_status_active;
    }
    if (item.isVisited) {
      return classes.Qpay_status_visited;
    }
    return "";
  };

  const renderElements = (items: BarItems[]) => {
    return items.map((item: BarItems, i) => {
      return (
        <div key={i} className={getClass(item)}>
          <span className={[classes.Web, "uppercase"].join(" ")}>
            {item.title}
          </span>
          <span className={classes.Mobile}>
            <ft-icon>{item.icon}</ft-icon>
          </span>
        </div>
      );
    });
  };

  return (
    <div className={classes.Qpay_container}>
      <div className={classes.Qpay_status}>{renderElements(items)}</div>
      <ft-linear-progress progress={progress || "0.1"}></ft-linear-progress>
    </div>
  );
};
