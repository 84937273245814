import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";

import * as requestUrl from "constants/request";
import noAuthUrls from "constants/noAuthUrls";
import { useParams } from "react-router-dom";

const requestUrls: { [key: string]: number } = Object.create(null, {});

export const useRequest = (scope?: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { customerKey } = useParams();

  const request: AxiosInstance = useMemo(() => {
    const reqObj = axios.create({
      baseURL: requestUrl.FT_REQUEST_BASE_URL,
    });

    reqObj.interceptors.request.use(
      async function (config) {
        const abortController = new AbortController();
        config.signal = abortController.signal;

        const url: string | undefined = config.url
          ?.replace("FT_CLIENT_KEY", customerKey ? customerKey : "")
          .trim();
        const shouldUseToken = !noAuthUrls.includes(config.url!);
        let newConfig: { url?: string; headers?: any } = {
          url,
        };

        if (shouldUseToken) {
          const token = !!scope
            ? await getAccessTokenSilently({ authorizationParams: { scope } })
            : await getAccessTokenSilently();
          if (!!token) {
            newConfig.headers = {
              Authorization: `Bearer ${token}`,
            };
          }
        }

        if (url) {
          if (requestUrls[url]) {
            abortController.abort();
          }
          requestUrls[url] = requestUrls[url] ? requestUrls[url] + 1 : 1;
        }

        return { ...config, ...newConfig };
      },
      function (error) {
        // Do something with request error
        return Promise.reject(error);
      }
    );

    // Add a request interceptor
    // Add a response interceptor
    reqObj.interceptors.response.use(
      function (response) {
        if (response.config.url) {
          if (requestUrls[response.config.url] === 1) {
            delete requestUrls[response.config.url];
          } else {
            requestUrls[response.config.url] =
              requestUrls[response.config.url] - 1;
          }
        }

        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
      },
      function (error) {
        if (error.config.url) {
          if (requestUrls[error.config.url] === 1) {
            delete requestUrls[error.config.url];
          } else {
            requestUrls[error.config.url] = requestUrls[error.config.url] - 1;
          }
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
      }
    );

    return reqObj;
  }, [getAccessTokenSilently, scope, customerKey]);

  return {
    request,
  };
};

export default useRequest;
