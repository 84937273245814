import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PageDetail {
  id: string;
  tab?: number;
  editMode?: boolean;
  data?: any;
}

export interface CommonState {
  isSlideOpen: boolean;
  isNavOpen: boolean;
  pageDetail: PageDetail | null;
  currentPaymentMethod: string | null;
}

const initialState: CommonState = {
  isSlideOpen: false,
  isNavOpen: false,
  pageDetail: null,
  currentPaymentMethod: null,
};

export const commonState = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateSlide(state, action: PayloadAction<any>) {
      state.isSlideOpen = true;
      state.pageDetail = action.payload;
    },
    updateNav(state, action: PayloadAction<any>) {
      state.isNavOpen = action.payload;
    },
    setCurrentPaymentMethod(state, action: PayloadAction<string | null>) {
      state.currentPaymentMethod = action.payload;
    },
    clearSlide(state) {
      if (state.isSlideOpen) state.isSlideOpen = false;
      if (state.pageDetail) state.pageDetail = null;
    },
  },
});

export const { updateSlide, clearSlide, updateNav, setCurrentPaymentMethod } =
  commonState.actions;
export default commonState.reducer;
