import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useRootDispatch, useRootSelector } from "store";
import { SliderPageWrapper } from "../../layout/sliderPageWrapper/SliderPageWrapper";
import { clearSlide } from "store/common/common-slice";

import { ADMINISTRATOR, OPERATOR, SUPERVISOR } from "constants/roles";

import classes from "./Payments.module.scss";
import { resetAccounts } from "store/accountLookup/accountLookup-slice";
import { resertPayments } from "store/paymentDetails/paymentDetails-slice";

interface PaymentsList {
  label: string;
  id: string;
  role: string[];
}

export const Payments = () => {
  const { t } = useTranslation(["payment", "common"]);
  const { customerKey } = useParams();
  const navigate = useNavigate();
  const dispatch = useRootDispatch();
  const [role] = useRootSelector((state) => state.user.roles);

  const payments: PaymentsList[] = useMemo(() => {
    const tempItem: PaymentsList[] = [
      {
        label: t("payment:enterPayments"),
        id: "enter_payment",
        role: [SUPERVISOR, OPERATOR],
      },
      {
        label: t("payment:deletePayments"),
        id: "delete_payment",
        role: [SUPERVISOR],
      },
      {
        label: t("payment:receipts"),
        id: "receipts",
        role: [SUPERVISOR],
      },
      {
        label: t("payment:reports"),
        id: "reports",
        role: [SUPERVISOR],
      },
      {
        label: t("payment:transmitEndOfDay"),
        id: "transmit_end_of_day",
        role: [SUPERVISOR, ADMINISTRATOR],
      },
    ];

    return tempItem;
  }, [t]);

  const navigateToPage = (page: string) => {
    navigate(`/${customerKey}/${page}`);
    dispatch(clearSlide());
  };

  const clickhandler = (list: PaymentsList) => {
    switch (list.id) {
      case "enter_payment":
        dispatch(resetAccounts());
        dispatch(resertPayments());
        navigateToPage("payments/add");
        break;
      case "transmit_end_of_day":
        navigateToPage("payments/end-of-day");
        break;
      case "delete_payment":
        navigateToPage("payments/delete");
        break;
      case "receipts":
        navigateToPage("payments");
        break;
      case "reports":
        navigateToPage("payments/reports");
        break;
      default:
        break;
    }
  };

  return (
    <SliderPageWrapper headerTitle={t("common:payments")}>
      <section className={classes.Container}>
        <ul>
          {payments
            .filter((single) => single.role.includes(role))
            .map((single, i) => (
              <li key={i} onClick={() => clickhandler(single)}>
                {single.label}
              </li>
            ))}
        </ul>
      </section>
    </SliderPageWrapper>
  );
};
