export const FT_REQUEST_BASE_URL = process.env.REACT_APP_FT_REQUEST_BASE_URL; // provide the base URL for request

export const FT_TSYS_SERVICE_URL = process.env.TSYS_HOST_URL;

export const FT_ASSET_REQUEST_PATH = `${process.env.REACT_APP_ASSETS_DOMAIN}/images/${process.env.REACT_APP_ASSETS_TENENT}/default/payment_center/`;

export const FT_GET_TOKEN = "users/guest";

export const FT_QUICK_PAY_LOAN_FETCH = "payment-center/account-information/";

export const FT_USERS = "users";
export const FT_USERS_EMAIL = "users/email";
export const FT_USERS_MFA = "users/mfa";
export const FT_USERS_RESETPASSWORD = "users/password-reset";
export const FT_USERS_CREATE = "users/registrations";
export const FT_USERS_VERIFICATION = "users/registrations/verification";
export const FT_USERS_PAYMENT_CENTER_INFO = "users/payment-center";

export const FT_ADD_PAYMENT_METHOD = "payment-center/paymentMethod";
export const FT_PAYMENT_METHODS = "payment-center/paymentMethod";

export const FT_PAYMENT_ACTIVITY = "payment-center/paymentActivity";

export const FT_CLIENT_CONFIG = "clients/FT_CLIENT_KEY/config";
export const FT_PAYMENT_CENTER_CONFIG =
  "payment-center/configuration/FT_CLIENT_KEY";

export const FT_TSYS_CONFIG = "ewallet/tsys-config";
export const FT_AUTO_PAY = "scheduled-payments/recurring";
export const FT_SCHEDULE_PAYMENT = "scheduled-payments";
export const FT_ACCOUNT_LOOKUP = "payment-center/account-information";
export const FT_ACCOUNT_ATTACH = "payment-center/account-information/attach";
export const FT_PROCESS_PAYMENT = "payment-center/payment";
export const FT_PROCESS_PAYMENT_BATCH = "payment-center/payment/batch";
export const FT_PROCESS_PAYMENT_EOD = "payment-center/eod";
export const FT_AGENT = "agents/biller";
export const FT_USERS_ADMIN_OPERATOR = "users/admin/users";
export const FT_REFUNDS_REVERSALS = "refunds/reversal-codes";
