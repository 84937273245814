import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NotificationType = "SUCCESS" | "ERROR" | "DEFAULT";
export interface NotificationState {
  show: boolean;
  messages: string[];
  type: NotificationType;
}

const initialState: NotificationState = {
  show: false,
  messages: [""],
  type: "DEFAULT",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    showNotification(state, action: PayloadAction<NotificationState>) {
      state.show = action.payload.show;
      state.messages = action.payload.messages;
      if (action.payload.type) state.type = action.payload.type;
    },
    clearNotification(state) {
      state.show = false;
      state.messages = [""];
      state.type = initialState.type;
    },
  },
});

export const { showNotification, clearNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
