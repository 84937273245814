import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useRootSelector } from "store/hooks";
import { useAssets } from "hooks";

import classes from "./Main.module.scss";

interface MainProps {}

export const Main: React.FC<MainProps> = () => {
  const { t } = useTranslation(["login", "common", "quickpay"]);
  const { client } = useRootSelector((state) => state.config);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { customerKey } = useParams();
  const { createAssetUrl } = useAssets();

  const { loginWithRedirect, isLoading, logout, isAuthenticated } = useAuth0<{
    email_verified: boolean;
  }>();

  const logonButtonHandler = () => {
    if (!!client && !client.customerKey) {
      console.warn("There is no customer key on the client object!", client);
      if (!!window.dtrum) {
        window.dtrum.reportCustomError(
          "no-customer-key",
          `The client ${client.idPId} has no customer key.`,
          "Logon Button Handler - Main.tsx"
        );
      }
    }
    loginWithRedirect();
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(`/${customerKey}/dashboard`);
    }
  }, [isAuthenticated, navigate, customerKey]);

  useEffect(() => {
    if (searchParams.get("logout")) {
      console.log(logout);
      logout();
    } else if (searchParams.get("doLogin")) {
      logonButtonHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // Note: This could be an anonymous function inline the return of getLogon but I thought it was cleaner on its own
  const getHeader = () => {
    return (
      <section className={classes.Branding}>
        <div>
          <img
            className={classes.Logo}
            src={createAssetUrl("logo")}
            alt={client.defaultApplicationName}
          />
        </div>
      </section>
    );
  };
  const getFormButtons = () => {
    let logonButton: any;
    let registerButton: any;
    if (client.userRegistrationOption! >= 1) {
      logonButton = (
        <ft-button
          class={classes.Login}
          fullwidth
          label={t("common:login_label")}
          unelevated
          onClick={logonButtonHandler}
        ></ft-button>
      );
    }

    if (client.userRegistrationOption === 1) {
      registerButton = (
        <ft-button
          class="outline-button"
          fullwidth
          label={t("login:create_account_label")}
          onClick={() =>
            loginWithRedirect({
              authorizationParams: { screen_hint: "signup" },
            })
          }
          outlined
        ></ft-button>
      );
    }

    return (
      <>
        {logonButton}
        {registerButton}
      </>
    );
  };
  const getLogonForm = () => {
    if (client.userRegistrationOption && client.userRegistrationOption !== 0) {
      return (
        <section>
          <div className={classes.Form}>{getFormButtons()}</div>
        </section>
      );
    } else {
      return null;
    }
  };
  const getLogonText = () => {
    const logonText = t("common:disclaimer");
    if (!!logonText && logonText !== "disclaimer") {
      return (
        <section className={classes.Disclaimer}>
          <div>{logonText}</div>
        </section>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className={classes.Container}>
      {getHeader()}
      {getLogonForm()}
      {getLogonText()}
      {isLoading && <ft-screen-loader></ft-screen-loader>}
    </div>
  );
};
