import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store";
import { GenericStateType, defaultGenericState } from "store/types";
import { deletePayment, fetchPayments } from "./paymentActivity-action";
import { groupPaymentsByConfirmationNumber } from "utility/payment";

export interface PaymentActivity {
  id: string;
  paymentDate: Date;
  paymentMethodType?: number;
  paymentMethodName?: string;
  amountPaid: number;
  status?: number;
  amountDue?: number;
  confirmationNumber: number;
  feeTotal?: number;
  emailSent?: boolean;
  schedulePayment?: boolean;
  dataObj?: any;
  accountNumber?: string;
  userId?: number;
  paymentChannel?: {
    locationCode: string;
    tenderedAmount: number;
    customerName: string;
  };
  linkedData?: PaymentActivity[];
}

export interface PaymentActivityMeta {
  pageNum?: number;
  totalCount?: number;
  sortColumn?: number;
  sortDir?: number;
}

interface PaymentActivityState {
  meta?: PaymentActivityMeta;
  serviceMeta: GenericStateType;
}

export interface FetchPayloadResult extends PaymentActivityMeta {
  results: PaymentActivity[];
}

const paymentActivityEntityAdapter = createEntityAdapter<PaymentActivity>({
  selectId: (payments) => payments.confirmationNumber,
});

const paymentActivitySlice = createSlice({
  name: "paymentActivity",
  initialState:
    paymentActivityEntityAdapter.getInitialState<PaymentActivityState>({
      serviceMeta: { ...defaultGenericState },
    }),
  reducers: {
    resetPayments(state) {
      paymentActivityEntityAdapter.removeAll(state);
      state.meta = undefined;
      state.serviceMeta = { ...defaultGenericState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPayments.fulfilled,
      (state, action: PayloadAction<FetchPayloadResult>) => {
        state.serviceMeta.status = "idle";
        state.serviceMeta.isSuccess = true;
        state.serviceMeta.isLoading = false;
        const { results, ...meta } = action.payload;

        const resultObj: PaymentActivity[] =
          groupPaymentsByConfirmationNumber(results);

        state.meta = meta;
        paymentActivityEntityAdapter.setAll(state, resultObj);
      }
    );
    builder.addCase(fetchPayments.pending, (state) => {
      state.serviceMeta.isLoading = true;
      state.serviceMeta.status = "inProcess";
    });
    builder.addCase(fetchPayments.rejected, (state) => {
      state.serviceMeta.isLoading = false;
      state.serviceMeta.status = "idle";
    });
    builder.addCase(
      deletePayment.fulfilled,
      (state, { payload: id }: PayloadAction<number>) => {
        paymentActivityEntityAdapter.removeOne(state, id);
        const totalCount = state.meta?.totalCount;
        if (totalCount && state.meta) state.meta.totalCount = totalCount - 1;
        state.serviceMeta.status = "idle";
        state.serviceMeta.isSuccess = true;
        state.serviceMeta.isLoading = false;
      }
    );
    builder.addCase(deletePayment.pending, (state) => {
      state.serviceMeta.isLoading = true;
      state.serviceMeta.status = "inProcess";
    });
    builder.addCase(deletePayment.rejected, (state) => {
      state.serviceMeta.isLoading = false;
      state.serviceMeta.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllPayments,
  selectById: selectPaymentById,
  selectIds: selectPaymentsIds,
} = paymentActivityEntityAdapter.getSelectors(
  (state: RootState) => state.paymentActivity
);

export const selectPaymentSlice = (state: RootState) => state.paymentActivity;

export const { resetPayments } = paymentActivitySlice.actions;
export default paymentActivitySlice.reducer;
