import { useTranslation } from "react-i18next";
import { NotificationType } from "../../../../store/notification/notification-slice";

import classes from "./NotificationMsg.module.scss";

export interface NotificationMsgProps {
  type?: NotificationType;
  messages: string[];
}

export const NotificationMsg: React.FC<NotificationMsgProps> = ({
  type,
  messages,
}) => {
  const { t } = useTranslation(["common"]);

  const wrapperClass = [classes.Default];
  if (type === "ERROR") {
    wrapperClass.push(classes.Error);
  } else if (type === "SUCCESS") {
    wrapperClass.push(classes.Success);
  }

  return (
    <>
      <div className={wrapperClass.join(" ")}>
        {messages.map((single, i) => (
          <p key={i} className={classes.Messages}>
            {t(`common:${single}`)}
            {/* <Trans
              i18nKey={`common:${single}`}
              t={t}
              defaults={single}
              components={[<a href="https://www.busey.com/">here</a>]}
            /> */}
          </p>
        ))}
      </div>
    </>
  );
};
