import { promptedRoute } from "routes/SlideRoute";
import { useRootSelector } from "../../../store/hooks";
import { BarItems, QuickPayBar } from "../../common/itemBar/ItemBar";
import { Notification } from "../../common/notification/Notification";

import classes from "./SliderPageWrapper.module.scss";

export interface SliderPageWrapperProps {
  headerTitle: string;
  quickbarItems?: { items: BarItems[]; progress: string };
  children?: React.ReactNode;
  closeButtonHandler?: () => void;
}

export const SliderPageWrapper: React.FC<SliderPageWrapperProps> = ({
  headerTitle,
  children,
  quickbarItems,
  closeButtonHandler,
}) => {
  const { isSlideOpen, isNavOpen, pageDetail } = useRootSelector(
    (state) => state.common
  );
  const notification = useRootSelector((state) => state.notification);

  const closeClickHandler = () => {
    const res = pageChecker();
    if (res) {
      closeDrawer();
      if (closeButtonHandler) closeButtonHandler();
    }
  };

  const closeDrawer = () => {
    const drawer: any = document.getElementsByTagName("ft-drawer")[0];
    if (drawer) {
      drawer.open = false;
    }
  };

  const pageChecker = () => {
    const res = promptedRoute.filter((single) => {
      return pageDetail?.id === single;
    });

    if (res.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <section
      className={[classes.Wrapper, isNavOpen ? classes.NavOpen : ""].join(" ")}
    >
      <div className={classes.Header}>
        <ft-icon onClick={closeClickHandler} class={classes.Icon}>
          close
        </ft-icon>
      </div>
      {isSlideOpen && notification.show ? <Notification /> : ""}
      <div className={classes.Container}>
        <p className={classes.Heading}>{headerTitle}</p>
        {quickbarItems ? (
          <div className={classes.InnerWrapper}>
            <QuickPayBar
              items={quickbarItems.items}
              progress={quickbarItems.progress}
            />
          </div>
        ) : null}
        {children}
      </div>
    </section>
  );
};
