import React, { ComponentType, memo } from "react";
import { useNavigate } from "react-router-dom";

import withAuthenticationRequired from "./withAuthenticationRequired";
import { useRootSelector } from "store";

interface AuthenticationGuardProps {
  component: ComponentType;
  roles?: string[];
}

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = memo(
  ({ component, roles }) => {
    const Component = withAuthenticationRequired(component, {
      onRedirecting: () => <ft-screen-loader></ft-screen-loader>,
      roles,
    });
    return <Component />;
  }
);

const withRoleRequired = (
  Component: React.FC,
  options: { roles: string[] } = { roles: [] }
): React.FC => {
  return (props) => {
    const { roles } = useRootSelector((state) => state.user);
    const { client } = useRootSelector((state) => state.config);
    const navigate = useNavigate();

    if (options.roles.some((userRole) => roles.includes(userRole))) {
      return <Component {...props} />;
    } else {
      navigate(`/${client.customerKey}/dashboard`);
      return null;
    }
  };
};

/**
 * Create a component that checks that the current user's roles contain at least one of the supplied roles.
 * @param props component and roles
 * @returns Route guarded component
 */
export const RoleGuard: React.FC<{
  component: React.FC;
  roles: string[];
}> = (props) => {
  const RoleComponent = withRoleRequired(props.component, {
    roles: props.roles,
  });

  return <RoleComponent />;
};
