import { Dictionary, EntityId, SerializedError } from "@reduxjs/toolkit";

interface PageDetail {
  id: string;
  tab?: number;
  editMode?: boolean;
  data?: any;
}

export interface CommonState {
  isSlideOpen: boolean;
  isNavOpen: boolean;
  pageDetail: PageDetail | null;
  currentPaymentMethod: string | null;
}

export interface EntityState<T> {
  ids: EntityId[];
  entities: Dictionary<T>;
}

export interface GenericStateType {
  isLoading: boolean;
  isSuccess?: boolean;
  error: null | any;
  status: "idle" | "inProcess" | "error" | "success";
}

export const defaultGenericState: GenericStateType = {
  isLoading: false,
  error: null,
  status: "idle",
};

export interface RejectedAction<ThunkArg> {
  type: string;
  payload: undefined;
  error: SerializedError | any;
  meta: {
    requestId: string;
    arg: ThunkArg;
    aborted: boolean;
    condition: boolean;
  };
}

export interface RejectedWithValueAction<ThunkArg, RejectedValue> {
  type: string;
  payload: RejectedValue;
  error: { message: "Rejected" };
  meta: {
    requestId: string;
    arg: ThunkArg;
    aborted: boolean;
  };
}
