import { AxiosInstance } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import * as requestUrl from "constants/request";
import { ReversalCodes } from "./reversalCodes-state";

export type FetchReversalCodes = typeof fetchReversalCodes;

export const fetchReversalCodes = createAsyncThunk<
  ReversalCodes[],
  {
    request: AxiosInstance;
  }
>("reversalCodes/fetchReversalCodes", async ({ request }) => {
  let reversalCodesUrl = new URL(requestUrl.FT_REFUNDS_REVERSALS, requestUrl.FT_REQUEST_BASE_URL);

  const response = await request(reversalCodesUrl.toString(), {
    method: "GET",
  });
  
  return response.data;
});