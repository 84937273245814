import { useEffect } from "react";

import { useRootDispatch, useRootSelector } from "../../../store/hooks";
import { clearNotification } from "../../../store/notification/notification-slice";

import { NotificationMsg } from "./notificationMsg/NotificationMsg";

export const Notification = () => {
  const notification = useRootSelector((state) => state.notification);
  const dispatch = useRootDispatch();

  useEffect(() => {
    if (notification.show) {
      setTimeout(() => {
        dispatch(clearNotification());
      }, 10000);
    }
  }, [notification.show, dispatch]);

  return (
    <>
      {notification.show ? (
        <NotificationMsg
          type={notification.type}
          messages={notification.messages}
        />
      ) : null}
    </>
  );
};
