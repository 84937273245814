import { lazy } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import { Login } from "components/pages/account/login/Login";
import { Main } from "components/pages/account/login/main/Main";
import { NotFound } from "components/pages/notFound/NotFound";

import { AuthenticationGuard } from "../auth0/authentication-guard";

// import { DashboardRoutes } from "./DashboardRoutes";
// import { PaymentsRoutes } from "./PaymentsRoutes";
import { ErrorPageComponent } from "components/pages/account/login/error/ErrorPage";
import { LoginRedirect } from "components/pages/account/login/redirect/LoginRedirect";

const DashboardRoutes = lazy(() =>
  import("./DashboardRoutes").then(({ DashboardRoutes }) => ({
    default: DashboardRoutes,
  }))
);
const PaymentsRoutes = lazy(() =>
  import("./PaymentsRoutes").then(({ PaymentsRoutes }) => ({
    default: PaymentsRoutes,
  }))
);

export const MainRoute = () => {
  const { t } = useTranslation(["routing"]);
  const { isLoading, isAuthenticated } = useAuth0();

  let currentLocation = useLocation();

  // Map of page titles - NOTE keeping this all here for now but may move to
  // its own file later
  const titleMap: any = {
    "/payments/add": `${t("routing:payment_details")}`,
    "/payments/end-of-day": `${t("routing:payment_eod")}`,
    "/payments/delete": `${t("routing:payment_delete")}`,
    "/payments/reports": `${t("routing:payment_reports")}`,
    "/quick-payment": `${t("routing:quickpay_payment")}`,
    "/quick-review": `${t("routing:quickpay_review")}`,
    "/quick-confirmation": `${t("routing:quickpay_confirmation")}`,
    "/settings": "Settings",
    "/*": `${t("routing:not_found")}`,
  };

  // Set the page title based on routing for 508 compliance and to provide information to
  // Google analytics

  // Remove clientID from the path
  let currentPath = currentLocation.pathname.replace(
    `/${localStorage.getItem("lastSavedKey")}`,
    ""
  );
  let currentTitle = "";

  if (
    currentPath !== "/" &&
    currentPath !== "" &&
    !!titleMap[`${currentPath}`]
  ) {
    currentTitle = titleMap[`${currentPath}`];
  } else if (
    currentPath !== "/" &&
    currentPath !== "" &&
    !titleMap[`${currentPath}`]
  ) {
    Object.getOwnPropertyNames(titleMap).forEach((path) => {
      if (currentTitle === "" && currentPath.startsWith(path)) {
        currentTitle = titleMap[`${path}`];
      }
    });
  }

  // If we have the default path check to see if the user is logged in or not
  if (currentPath === "/" || currentPath === "") {
    if (!isLoading && !isAuthenticated) {
      currentTitle = `${t("routing:login")}`;
    } else if (!isLoading && isAuthenticated) {
      currentTitle = `${t("routing:dashboard")}`;
    }
  }

  document.title =
    currentTitle && currentTitle !== ""
      ? currentTitle
      : `${t("routing:default")}`;

  return useRoutes([
    {
      path: "/",
      element: <Login />,
      children: [
        {
          index: true,
          element: <Main />,
        },
        {
          path: "/error",
          element: <ErrorPageComponent />,
        },
        {
          path: "/login",
          element: <LoginRedirect />,
        },
      ],
    },
    {
      path: "dashboard/*",
      element: <AuthenticationGuard component={DashboardRoutes} />,
    },
    {
      path: "payments/*",
      element: <PaymentsRoutes />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
};
