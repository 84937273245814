import agent from "./agent/agent-slice";
import notification from "./notification/notification-slice";
import account from "./account/account-slice";
import common from "./common/common-slice";
import config from "./config/config-slice";
import user from "./user/user-slice";
import accountSummary from "./accountSummary/accountSummary-slice";
import accountLookup from "./accountLookup/accountLookup-slice";
import paymentDetails from "./paymentDetails/paymentDetails-slice";
import paymentActivity from "./paymentActivity/paymentActivity-slice";
import operator from "./operator/operator-slice";
import receipts from "./receipts/receipts-slice";
import report from "./report/report-slice";
import reversalCodes from "./reversalCodes/reversalCodes-slice"

const reducer = {
  agent,
  notification,
  account,
  common,
  config,
  user,
  accountSummary,
  accountLookup,
  paymentDetails,
  paymentActivity,
  operator,
  receipts,
  report,
  reversalCodes,
};

export default reducer;
