import { AxiosInstance } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  FT_PROCESS_PAYMENT_BATCH,
  FT_PROCESS_PAYMENT_EOD,
  FT_REQUEST_BASE_URL,
} from "constants/request";

import { EoDTO, Payment } from "./paymentDetails-slice";
import { PaymentActivity } from "store/paymentActivity/paymentActivity-slice";

export type MakePayment = typeof makePayment;
export type MakePaymentData = {
  payments: Payment[];
  paymentChannel: {
    paymentChannelType: number;
    tenderedAmount: number;
    customerName: string;
  };
};

export interface FetchEodProps {
  locationCode?: string;
  clientId?: number;
  overrideTransactionWindow?: boolean;
}

function makeEodUrl(data: FetchEodProps): string {
  let eodUrl = new URL(`${FT_PROCESS_PAYMENT_EOD}/`, FT_REQUEST_BASE_URL);
  if (!!data && !!data.clientId) {
    eodUrl = new URL(`${data.clientId}/`, eodUrl);
    if (!!data.locationCode) {
      eodUrl = new URL(`${data.locationCode}/`, eodUrl);
    }
  }
  if (!!data.overrideTransactionWindow) {
    eodUrl.searchParams.append("overrideTransactionWindow", "true");
  }
  return eodUrl.toString();
}

export const makePayment = createAsyncThunk<
  PaymentActivity[],
  {
    request: AxiosInstance;
    data: MakePaymentData;
  }
>("paymentDetail/makePayment", async ({ request, data }) => {
  const payments = data.payments.map((single) => {
    const temp: any = {
      ...single,
      paymentDate: new Date().toUTCString(),
      paymentChannel: {
        ...data.paymentChannel,
        customerName: single.customerName,
      },
    };
    delete temp.id;
    return temp;
  });
  const response = await request(FT_PROCESS_PAYMENT_BATCH, {
    method: "POST",
    data: payments,
  });
  return response.data;
});

export type EndOfDayTransmit = typeof endOfDayTransmit;

export const endOfDayTransmit = createAsyncThunk<
  void,
  {
    request: AxiosInstance;
    data: FetchEodProps;
  }
>("paymentDetail/eod", async ({ request, data }) => {
  const url = makeEodUrl(data);
  const response = await request(url, {
    method: "POST",
  });
  return response.data;
});

export type FetchEndOfDayTransmit = typeof fetchEndOfDayTransmit;

export const fetchEndOfDayTransmit = createAsyncThunk<
  EoDTO,
  {
    request: AxiosInstance;
    data: FetchEodProps;
  }
>("paymentDetail/fetchEod", async ({ request, data }) => {
  const url = makeEodUrl(data);
  const response = await request(url);
  return response.data;
});
