import { lazy, Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// import { App } from "App";
import { NotFound } from "components/pages/notFound/NotFound";
import { Auth0ProviderWithNavigate } from "auth0/auth0-provider-with-navigate";

import { ErrorHandler } from "../ErrorHandler";

const App = lazy(() =>
  import("App").then(({ App }) => ({
    default: App,
  }))
);

export interface ClientRouteProps {}

export const ClientRoute: React.FC<ClientRouteProps> = () => {
  const browserRouter = createBrowserRouter([
    {
      path: "/",
      element: <NotFound />,
    },
    {
      path: "/undefined",
      element: <NotFound />,
    },
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: ":customerKey/*",
      element: (
        <Auth0ProviderWithNavigate>
          <ErrorHandler>
            <Suspense fallback={<ft-screen-loader></ft-screen-loader>}>
              <App />
            </Suspense>
          </ErrorHandler>
        </Auth0ProviderWithNavigate>
      ),
    },
  ]);

  return <RouterProvider router={browserRouter} />;
};
