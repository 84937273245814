import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store";
import { GenericStateType, defaultGenericState } from "store/types";
import { fetchAccount } from "./accountLookup-action";

export interface AccountLookup {
  id: string;
  accountNumber: string;
  wireNo: string;
}

interface AccountLookupState extends GenericStateType {
  selectedId?: string;
}

const accountLookupEntityAdapter = createEntityAdapter<AccountLookup>({});

const accountLookupSlice = createSlice({
  name: "accountLookup",
  initialState: accountLookupEntityAdapter.getInitialState<AccountLookupState>({
    ...defaultGenericState,
  }),
  reducers: {
    resetAccounts(state) {
      accountLookupEntityAdapter.removeAll(state);
    },
    updateSelectedId(state, action: PayloadAction<string>) {
      state.selectedId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAccount.fulfilled,
      (state, action: PayloadAction<AccountLookup>) => {
        state.status = "idle";
        state.isSuccess = true;
        state.isLoading = false;
        state.selectedId = action.payload.id;
        accountLookupEntityAdapter.upsertOne(state, action.payload);
      }
    );
    builder.addCase(fetchAccount.pending, (state) => {
      state.isLoading = true;
      state.status = "inProcess";
    });
    builder.addCase(fetchAccount.rejected, (state) => {
      state.isLoading = false;
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllAccounts,
  selectById: selectAccountById,
  selectIds: selectAccountIds,
} = accountLookupEntityAdapter.getSelectors(
  (state: RootState) => state.accountLookup
);

export const selectAccountSlice = (state: RootState) => state.accountLookup;

export const { resetAccounts, updateSelectedId } = accountLookupSlice.actions;
export default accountLookupSlice.reducer;
