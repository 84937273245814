import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "components/common";

import classes from "./Dashboard.module.scss";

export const Dashboard = () => {
  const { t } = useTranslation(["app"]);

  const updatePopupState = () => {
    localStorage.setItem("hasPopupShown", "1");
  };

  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const hasPopupShown = localStorage.getItem("hasPopupShown");
    setShowPopup(!hasPopupShown);
  }, []);

  return (
    <>
      <section className={classes.Container}>
        <p>{t("dashboardInstructions")}</p>
        <p>{t("dashboardInstructions_secondline")}</p>
      </section>
      {showPopup && (
        <Dialog
          scrimClickAction={true}
          type="warning"
          primaryActionHandler={updatePopupState}
          primaryActionLabel={t("CONTINUE")}
          showSecondaryAction={false}
          closeIcon={false}
          dialogClosed={updatePopupState}
        >
          <p className={classes.DialogText}>{t("common:disclaimer")}</p>
        </Dialog>
      )}
    </>
  );
};
