import { Outlet } from "react-router-dom";

import { SidePageWrapper } from "../../../layout/sidePageWrapper/SidePageWrapper";

export const Login = () => {
  return (
    <SidePageWrapper small={true} hideDisclaime={true}>
      <Outlet />
    </SidePageWrapper>
  );
};
