import classes from "./NotFound.module.scss";

export const NotFound = () => {
  return (
    <>
      <div className={classes.NotFound}>
        <h2>Page Not Found</h2>
      </div>
    </>
  );
};
