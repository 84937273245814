import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { LoadFetchDO } from "store/common";

export type PaymentType = "standard" | "principal" | "escrow";

export interface AccountInfoResponse {}

interface AccountExtends {
  [key: string]: any;
}

export interface AccountInfo extends AccountExtends {
  accountNumber: string;
  accountType: string;
  amountDue: number;
  dueDate: string;
  balance: number;
  id: number;
  active: boolean;
  userId?: number;
  minimumAmountDue?: number;
  clientId: number;
}

export interface AccountState {
  accountInfo: AccountInfo | null;
  allAccounts: AccountInfo[];
  loadFetch: LoadFetchDO | null;
}

const initialState: AccountState = {
  accountInfo: null,
  allAccounts: [],
  loadFetch: null,
};

const accountEntityAdapter = createEntityAdapter<AccountState>({});

export const accountSlice = createSlice({
  name: "account",
  initialState: accountEntityAdapter.getInitialState(initialState),
  reducers: {
    setAccountInfo(state, action: PayloadAction<AccountInfo[]>) {
      const temp = action.payload[0];
      // temp.accountType = "C";
      state.allAccounts = action.payload;
      state.accountInfo = temp;
    },
    changeAccount(state, action: PayloadAction<AccountInfo>) {
      state.accountInfo = action.payload;
    },
    setIsLoading(state, action: PayloadAction<LoadFetchDO | null>) {
      state.loadFetch = action.payload;
    },
    resetInfo(state) {
      state.allAccounts = initialState.allAccounts;
      state.accountInfo = initialState.accountInfo;
      state.loadFetch = initialState.loadFetch;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      accountEntityAdapter.removeAll(state);
    });
  },
});

export const { setAccountInfo, setIsLoading, resetInfo, changeAccount } =
  accountSlice.actions;
export default accountSlice.reducer;
