import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store";
import { defaultGenericState } from "store/types";
import { fetchReversalCodes } from "./reversalCodes-action";
import { ReversalCodes, ReversalCodesState } from "./reversalCodes-state";

const reversalCodesEntityAdapter = createEntityAdapter<ReversalCodes>({});

const reversalCodesSlice = createSlice({
  name: "reversalCodes",
  initialState: reversalCodesEntityAdapter.getInitialState<ReversalCodesState>({
    ...defaultGenericState,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchReversalCodes.fulfilled,
      (state, action: PayloadAction<ReversalCodes[]>) => {
        state.status = "idle";
        state.isSuccess = true;
        state.isLoading = false;
        reversalCodesEntityAdapter.setAll(state, action.payload);
      }
    );
    builder.addCase(fetchReversalCodes.pending, (state) => {
      state.isLoading = true;
      state.status = "inProcess";
    });
    builder.addCase(fetchReversalCodes.rejected, (state) => {
      state.isLoading = false;
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllReversalCodes
} = reversalCodesEntityAdapter.getSelectors((state: RootState) => state.reversalCodes);
export default reversalCodesSlice.reducer;
