import { AxiosInstance } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { FT_AGENT, FT_REQUEST_BASE_URL } from "constants/request";

import { AgentDTO } from "./agent";

export interface FetchAgentProps {
  agentCode: string;
}

export type FetchAgent = typeof fetchAgent;

export const fetchAgent = createAsyncThunk<
  AgentDTO,
  {
    request: AxiosInstance;
    data: FetchAgentProps;
  }
>("agent/fetchAgent", async ({ request, data }): Promise<AgentDTO> => {
  const url = new URL(FT_AGENT, FT_REQUEST_BASE_URL);
  url.searchParams.append("agentBillerCode", data.agentCode);
  url.searchParams.append("billerCode", "13");
  const response = await request(url.toString());
  return response.data as AgentDTO;
});
