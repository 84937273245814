import { useTranslation } from "react-i18next";

import classes from "./SidePageWrapper.module.scss";
export interface SidePageWrapperProps {
  small?: boolean;
  hideDisclaimeIcon?: boolean;
  hideDisclaime?: boolean;
  disclaimerText?: string;
  bannerHeading?: string;
  bannerParas?: string[];
  hideBannerWidget?: boolean;
  children?: React.ReactNode;
}

export const SidePageWrapper: React.FC<SidePageWrapperProps> = (props) => {
  const { t } = useTranslation(["common"]);
  let wrapperClass = [classes.Wrapper];
  if (props.small) wrapperClass = [...wrapperClass, classes.Small];

  let containerClass = [classes.Wrapper_right_container];
  if (props.small)
    containerClass = [
      ...containerClass,
      classes.Wrapper_right_container_center,
    ];

  const renderParas = (paras: string[]) => {
    return paras.map((single: string, i) => {
      return <p key={i}>{single}</p>;
    });
  };

  return (
    <section className={wrapperClass.join(" ")}>
      <div className={classes.Wrapper_left}>
        {props.hideBannerWidget ? null : (
          <div className={classes.Wrapper_left_message}>
            {props.bannerHeading && <h2>{props.bannerHeading} </h2>}
            {props.bannerParas && renderParas(props.bannerParas)}
          </div>
        )}
      </div>
      <div className={classes.Wrapper_right}>
        <div className={containerClass.join(" ")}>
          <div className={classes.Container}>{props.children}</div>
        </div>
        {props.hideDisclaime ? null : (
          <div className={classes.Wrapper_right_disclaimer}>
            <div className={classes.Wrapper_right_disclaimer_icon}>
              {props.hideDisclaimeIcon ? null : <ft-icon>lock</ft-icon>}
            </div>

            <div className={classes.Wrapper_right_disclaimer_text}>
              <p>
                {props.disclaimerText
                  ? props.disclaimerText
                  : t("common:privacy")}
              </p>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
