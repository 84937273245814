import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store";
import { GenericStateType, defaultGenericState } from "store/types";
import { fetchReport } from "./report-action";
import {
  FetchPayloadResult,
  PaymentActivity,
  PaymentActivityMeta,
} from "store/paymentActivity/paymentActivity-slice";
import { groupPaymentsByConfirmationNumber } from "utility/payment";

interface ReportState {
  meta?: PaymentActivityMeta;
  serviceMeta: GenericStateType;
}

const reportEntityAdapter = createEntityAdapter<PaymentActivity>({});

const reportSlice = createSlice({
  name: "report",
  initialState: reportEntityAdapter.getInitialState<ReportState>({
    serviceMeta: { ...defaultGenericState },
  }),
  reducers: {
    resetreport(state) {
      reportEntityAdapter.removeAll(state);
      state.meta = undefined;
      state.serviceMeta = { ...defaultGenericState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchReport.fulfilled,
      (state, action: PayloadAction<FetchPayloadResult>) => {
        state.serviceMeta.status = "idle";
        state.serviceMeta.isSuccess = true;
        state.serviceMeta.isLoading = false;
        const { results, ...meta } = action.payload;
        state.meta = meta;
        const groupRes = groupPaymentsByConfirmationNumber(results);
        reportEntityAdapter.setAll(state, groupRes);
      }
    );
    builder.addCase(fetchReport.pending, (state) => {
      state.serviceMeta.isLoading = true;
      state.serviceMeta.status = "inProcess";
    });
    builder.addCase(fetchReport.rejected, (state) => {
      state.serviceMeta.isLoading = false;
      state.serviceMeta.status = "idle";
    });
  },
});

export const { selectAll: selectAllReport } = reportEntityAdapter.getSelectors(
  (state: RootState) => state.report
);

export const { resetreport } = reportSlice.actions;
export default reportSlice.reducer;
