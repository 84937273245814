import { useAuth0 } from "@auth0/auth0-react";

import classes from "./Error.module.scss";
import { useRootSelector } from "store";
import { useAssets } from "hooks";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ErrorPageComponent = () => {
  const { createAssetUrl } = useAssets();
  const { logout } = useAuth0();
  const { client } = useRootSelector((state) => state.config);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation(["login", "common", "quickpay"]);

  const getHeader = () => {
    return (
      <section className={classes.Branding}>
        <div>
          <img
            className={classes.Logo}
            src={createAssetUrl("logo")}
            alt={client.defaultApplicationName}
          />
        </div>
      </section>
    );
  };
  const getLogonText = () => {
    const logonText = t("common:disclaimer");
    if (!!logonText && logonText !== "disclaimer") {
      return (
        <section className={classes.Disclaimer}>
          <div>{logonText}</div>
        </section>
      );
    } else {
      return <></>;
    }
  };

  const logonButtonHandler = () => {
    const pingLogoutUrl = process.env.REACT_APP_PING_LOGOUT_URL;

    if (!!pingLogoutUrl) {
      const logoutUrl = new URL(pingLogoutUrl);
      const targetResourceUrl = new URL(
        `/${client.customerKey}/login`,
        window.location.toString()
      );

      const form = document.createElement("form");
      form.method = "post";
      form.action = `${logoutUrl.toString()}?TargetResource=${targetResourceUrl.toString()}`;

      const targetResourceInput = document.createElement("input");
      targetResourceInput.type = "hidden";
      targetResourceInput.name = "TargetResource";
      targetResourceInput.value = targetResourceUrl.toString();

      form.appendChild(targetResourceInput);

      document.body.appendChild(form);

      form.submit();
    } else {
      const redirectUri = new URL(
        `/${client.customerKey}`,
        window.location.toString()
      );

      redirectUri.searchParams.append("doLogin", "1");

      logout({
        logoutParams: {
          federated: true,
          returnTo: redirectUri.toString(),
        },
      });
    }
  };

  const makeUnauthorizedError = () => {
    return (
      <div>
        <p>There was an error when performing authentication.</p>
        <p>Error message: {searchParams.get("error_description")}</p>
        <ft-button
          class={classes.Login}
          fullwidth
          label={t("common:login_label")}
          unelevated
          onClick={logonButtonHandler}
        ></ft-button>
      </div>
    );
  };

  const makeAccessDeniedError = () => {
    return (
      <div>
        <p>Access to the application is denied.</p>
        <p>{searchParams.get("error_description")}</p>
      </div>
    );
  };

  const getErrorSection = () => {
    switch (searchParams.get("error")) {
      case "unauthorized":
        return makeUnauthorizedError();
      case "access_denied":
        return makeAccessDeniedError();
      default:
        return <></>;
    }
  };

  //logout();
  return (
    <div className={classes.Container}>
      {getHeader()}
      <section className={classes.Login}>
        <div>{getErrorSection()}</div>
      </section>
      {getLogonText()}
    </div>
  );
};
