/*
 * Constants for Authorization
 */

/**
 * Scopes for ACH
 */
class ACHScopes {
  prefix = "ach";

  public read = `${this.prefix}:read`;
  public write = `${this.prefix}:write`;
}
/**
 * Scopes for agents
 */
class AgentScopes {
  prefix = "agents";

  public read: string = `${this.prefix}:read`;
  public readClientSpecific: string = `${this.prefix}:read:client`;
  // public readAll: string = `${this.prefix}:read:all`;
  public readReversalCodes: string = "reversal-codes:read";
}

/**
 * Scopes for billers
 */
class BillersScopes {
  prefix = "billers";
  public read: string = `${this.prefix}:read`;
}

/**
 * Scopes for client
 */
class ClientScopes {
  prefix = "clients";

  public readClientSpecific = `${this.prefix}:read:client`;
}

/**
 * Scopes for payment center
 */
class PaymentCenterScopes {
  prefix = "payment-center";

  public read = `${this.prefix}:read`;
  public write = `${this.prefix}:write`;
  public readClient = `${this.prefix}:read:client`;
  public writeClient = `${this.prefix}:write:client`;

  public deletePaymentMethod = `${this.prefix}:payment-methods:delete`;
}

/**
 * Scopes for payment router
 */
class PaymentRouterScopes {
  prefix = "payment-router";

  public read = `${this.prefix}:read`;
  public write = `${this.prefix}:write`;
}

class ScheduledPaymentScopes {
  prefix = "scheduled-payments";

  public read = `${this.prefix}:read:user`;
  public write = `${this.prefix}:write:user`;
}

/**
 * Scopes for users
 */
class UserScopes {
  prefix = "users";

  public readClientSpecific = `${this.prefix}:read:client`;

  public writeUser = `${this.prefix}:write`;
}

/**
 * Organized hash of all scopes.
 */
class AuthorizationScopes {
  /**
   * ACH service scopes
   */
  public ach = new ACHScopes();
  /**
   * Agent service scopes
   */
  public agents = new AgentScopes();
  /**
   * Biller service scopes
   */
  public billers = new BillersScopes();
  /**
   * Client service scopes
   */
  public clients = new ClientScopes();
  /**
   * Payment-center service scopes
   */
  public paymentCenter = new PaymentCenterScopes();
  /**
   * Payment-router service scopes
   */
  public paymentRouter = new PaymentRouterScopes();
  /**
   * Scheduled payments service scopes
   */
  public scheduledPayments = new ScheduledPaymentScopes();
  /**
   * User service scopes
   */
  public users = new UserScopes();
}

/**
 * List of all the scopes in the app.
 */
export const scopes: AuthorizationScopes = new AuthorizationScopes();

/**
 * List of all default scopes
 */
export const defaultScopes = ["openid", "profile", "email", "offline_access"];

/**
 * List of all operator scopes
 */
export const operatorScopes = [
  scopes.ach.read,
  scopes.ach.write,
  scopes.agents.read,
  scopes.agents.readReversalCodes,
  scopes.billers.read,
  scopes.paymentCenter.read,
  scopes.paymentCenter.write,
  scopes.scheduledPayments.read,
  scopes.scheduledPayments.write,
];

/**
 * List of all supervisor scopes
 */
export const supervisorScopes = [
  scopes.ach.read,
  scopes.ach.write,
  scopes.agents.read,
  scopes.agents.readReversalCodes,
  scopes.billers.read,
  scopes.paymentCenter.read,
  scopes.paymentCenter.readClient,
  scopes.paymentCenter.write,
  scopes.paymentCenter.writeClient,
  scopes.scheduledPayments.read,
  scopes.scheduledPayments.write,
  scopes.users.readClientSpecific,
];

/**
 * List of all admin scopes
 */
export const adminScopes = [
  scopes.ach.read,
  scopes.ach.write,
  scopes.agents.read,
  scopes.agents.readReversalCodes,
  scopes.billers.read,
  scopes.paymentCenter.read,
  scopes.paymentCenter.readClient,
  scopes.paymentCenter.write,
  scopes.paymentCenter.writeClient,
  scopes.scheduledPayments.read,
  scopes.scheduledPayments.write,
  scopes.users.readClientSpecific,
];

/**
 * The default scope used for requesting the access token from Auth0.
 */
export const defaultScope = defaultScopes.join(" ");
/**
 * The scopes specific for operators
 */
export const operatorScope = operatorScopes.join(" ");
/**
 * The scopes specific for supervisor
 */
export const supervisorScope = supervisorScopes.join(" ");
/**
 * The scopes specific for administrators
 */
export const adminScope = adminScopes.join(" ");
