import { useRoutes } from "react-router-dom";

import { Dashboard } from "components/pages/dashboard/Dashboard";
import { InnerContentLayout } from "components/layout/innerContentLayout/InnerContentLayout";

export const DashboardRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <InnerContentLayout>
          <Dashboard />
        </InnerContentLayout>
      ),
    },
  ]);
};
