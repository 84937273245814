import { useCallback, useMemo, useReducer, useRef } from "react";

interface InputState {
  value: string;
  isTouched: boolean;
  options?: any;
}

interface InputActionType {
  type: string;
  value?: any;
}

const initialInputState: InputState = {
  value: "",
  isTouched: false,
};

const inputStateReducer = (state: InputState, action: InputActionType) => {
  if (action.type === "INPUT") {
    return {
      value:
        action.value === "true"
          ? true
          : action.value === "false"
          ? false
          : action.value,
      isTouched: state.isTouched,
    };
  }
  if (action.type === "BLUR") {
    return { isTouched: true, value: state.value };
  }
  if (action.type === "RESET") {
    return { isTouched: false, value: "" };
  }
  return state;
};

export const useInput = (initialState?: InputState) => {
  let state = initialState;
  if (!state) {
    state = initialInputState;
  }

  const [inputState, dispatch] = useReducer(inputStateReducer, state);

  const ref = useRef(null);

  const valueChangeHandler = useCallback((event: Event) => {
    dispatch({
      type: "INPUT",
      value: (event.target as HTMLInputElement).value,
    });
  }, []);

  const valueCheckedHandler = useCallback((event: Event) => {
    dispatch({
      type: "INPUT",
      value: !(event.target as HTMLInputElement).checked,
    });
  }, []);

  const setValue = useCallback((value: any) => {
    dispatch({
      type: "INPUT",
      value: value,
    });
  }, []);

  const inputBlurHandler = useCallback((event: any) => {
    dispatch({ type: "BLUR" });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: "RESET" });
  }, []);

  const generateReturnObj = useMemo(() => {
    return {
      value: inputState.value,
      isTouched: inputState.isTouched,
      ref: ref,
      valueChangeHandler,
      inputBlurHandler,
      valueCheckedHandler,
      reset,
      setValue,
    };
  }, [
    inputState,
    valueChangeHandler,
    inputBlurHandler,
    reset,
    valueCheckedHandler,
    setValue,
  ]);

  return generateReturnObj;
};

export type UseInput = {
  value: any;
  isTouched: boolean;
  ref: React.MutableRefObject<any>;
  valueChangeHandler: (event: Event) => void;
  valueCheckedHandler: (event: Event) => void;
  inputBlurHandler: (event: any) => void;
  reset: () => void;
  setValue: (val: any) => void;
};
