import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// import reportWebVitals from "./reportWebVitals";

import { store, persistor } from "./store";
import { ClientRoute } from "routes";

import "@ft/firstech-ui";
import "rsuite/dist/rsuite.min.css";
import "index.scss";

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClientRoute />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals((metric) => {
//   if (!!window.dtrum) {
//     //window.dtrum.
//   }
// });
// reportWebVitals(console.log);
