import { format, getDayOfYear } from "date-fns";
import {
  AccountTypeConfiguration,
  FeeConfiguration,
  PaymentMethodConfiguration,
} from "store/config";
import { UserAccount } from "store/user/user";

export const renderConditionalProp = (condition: boolean, prop: string) => {
  const props: any = {};
  if (condition) props[prop] = true;
  return props;
};

export enum PAYMENT_METHOD_TYPE {
  ach = 1,
  card = 2,
  eWallet = 3,
  lockbox = 4,
}

export type PaymentMethodType = keyof typeof PAYMENT_METHOD_TYPE;

export enum PAYMENT_TYPE {
  S = "Standard",
  P = "Principal",
  E = "Escrow",
}

export type PaymentType = keyof typeof PAYMENT_TYPE;

const paymentMethodTypeMap: { [key: string]: number } = {
  ach: 1,
  card: 2,
  eWallet: 3,
  lockbox: 4,
};

const paymentAccountTypeMap: { [key: string]: number } = {
  checking: 1,
  savings: 2,
};

const amountTypeMap: { [key: string]: number } = {
  userEntered: 1,
  amountDue: 2,
  minimum: 3,
  total_balcnce: 4,
};

const paymentScheduleMap: { [key: string]: number } = {
  userProvided: 1,
  dueDate: 2,
};

const PaymentStatusTypeMap: { [key: string]: number } = {
  Canceled: 1,
  Paid: 2,
  Pending: 3,
  Refunded: 4,
  Scheduled: 5,
};

const ScheduleStatusTypeMap: { [key: string]: number } = {
  Canceled: 1,
  Skipped: 2,
  Scheduled: 0,
  Processed: 30,
};

const SortColumnTypeMap: { [key: string]: number } = {
  paymentDate: 1,
  confirmationNumber: 2,
  amountPaid: 3,
  amountDue: 4,
  paymentMethodName: 5,
  status: 5,
};

const SortDirTypeMap: { [key: string]: number } = {
  asc: 1,
  desc: 2,
};

const getKey = (obj: any, val: any) =>
  Object.keys(obj).find((key) => obj[key] === val);

export const typeMapper = (val: string | number, type: string) => {
  let returnVal;
  switch (type) {
    case "account":
      returnVal = paymentAccountTypeMap[val];
      break;
    case "PaymentStatus":
      returnVal = PaymentStatusTypeMap[val];
      break;
    case "ScheduleStatus":
      returnVal = ScheduleStatusTypeMap[val];
      break;
    case "SortColumn":
      returnVal = SortColumnTypeMap[val];
      break;
    case "SortDir":
      returnVal = SortDirTypeMap[val];
      break;
    case "amountType":
      returnVal = amountTypeMap[val];
      break;
    case "amountTypeReverse":
      returnVal = getKey(amountTypeMap, val);
      break;
    case "paymentSchedule":
      returnVal = paymentScheduleMap[val];
      break;
    case "paymentScheduleReverse":
      returnVal = getKey(paymentScheduleMap, val);
      break;
    case "accountReverse":
      returnVal = getKey(paymentAccountTypeMap, val);
      break;
    case "paymentMethodType":
      returnVal = paymentMethodTypeMap[val];
      break;
    case "paymentMethodTypeReverse":
      returnVal = getKey(paymentMethodTypeMap, val);
      break;
    case "SortColumnReverse":
      returnVal = getKey(SortColumnTypeMap, val);
      break;
    case "SortDirReverse":
      returnVal = getKey(SortDirTypeMap, val);
      break;
    case "PaymentStatusReverse":
      returnVal = getKey(PaymentStatusTypeMap, val);
      break;
    case "ScheduleStatusReverse":
      returnVal = getKey(ScheduleStatusTypeMap, val);
      break;
  }
  return returnVal;
};

export const getAddress = (user: UserAccount) => {
  return "".concat(
    user?.addressLine1!,
    " ",
    user?.addressLine2!,
    " ",
    user?.city!,
    " ",
    user?.state!
  );
};

/*
 * mask the whole string except last length provided.
 * @param {string} val
 * @param {string} count - last unmasked digit
 * @return {string}
 */

export const maskCardString = (val: string, count: number = 4) => {
  const showDigit = getLastDigit(val, count);
  const finalVal = `xxxx xxxx xxxx ${showDigit}`;
  return finalVal;
};

export const maskString = (val: string, count: number = 4) => {
  const showDigit = getLastDigit(val, count);
  const finalVal = showDigit.padStart(val.length, "x");
  return finalVal;
};

export const getLastDigit = (val: string, count: number = 4) =>
  val.slice(-count);

export const extractError = (
  error: any,
  defaultVal: string = "something_went_wrong"
) => {
  let errors: string[] = [defaultVal];
  if (error.response && error.response.data && error.response.data.errors) {
    const errObjects: any = Object.values(error.response.data.errors);
    errors = [].concat(...errObjects);
    if (errors.length > 0) {
      return errors;
    }
  }
  return errors;
};

/*
 * String conversion to Initil letter to upper case on whole string
 * @param {string} str
 * @return {string}
 */

export const toInitUpperCase = function (str: string) {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, " ")
    .replace(/^(.)/, function ($1) {
      return $1.toUpperCase();
    });
};

/*
 * parse data from expession - ex. "0 0 1 * *",
 * @param {string} expression
 * @return {string} 2nd value , 1 - in the expression example.
 */

export const getDateFromExpression = (expression: string) => {
  const res = expression.split(" ");
  return res[2];
};

/*
 * String conversion to Camel Case
 * @param {string} str
 * @return {string} in Camel Case
 */

export const toCamelCase = (str: string) => {
  return str
    .replace(/\s(.)/g, function ($1) {
      return $1.toUpperCase();
    })
    .replace(/\s/g, "")
    .replace(/^(.)/, function ($1) {
      return $1.toLowerCase();
    });
};

/*
 * Fetch data from config based on type
 * @param {AccountTypeConfiguration} config
 * @param {string} type
 * @return {any} based on logic and requirnment
 */

export const fetchFromConfig = (
  config: AccountTypeConfiguration,
  type: string
) => {
  if (type === "initialPaymentTypes")
    return Object.keys(config.paymentTypes)[0];
};

/*
 * Check if the amount is in range
 * @param {PaymentMethodConfiguration} currentPaymentConfig
 * @param {number | undefined} amountDue
 * @return {boolean}
 */

export const checkLoanInRange = (
  amountDue: number | undefined,
  currentPaymentConfig: PaymentMethodConfiguration
) => {
  const minLoanAmount = currentPaymentConfig.payments.minimumPaymentAmount;
  const maxLoanAmount = currentPaymentConfig.payments.maximumPaymentAmount;

  return minLoanAmount && maxLoanAmount
    ? amountDue && amountDue >= minLoanAmount && amountDue <= maxLoanAmount
    : false;
};

export const calculateFee = (
  convenienceFee?: FeeConfiguration | null,
  defaultFee?: number,
  amount?: number
): number => {
  let fee = 0;
  if (convenienceFee && convenienceFee?.amount) {
    if (convenienceFee.isPercentage) {
      fee = amount ? amount * convenienceFee.amount : 0;
    } else {
      fee = convenienceFee.amount;
    }
  } else {
    fee = defaultFee ? defaultFee : 0;
  }

  return parseFloat(fee.toFixed(2));
};

export const dateTimeFormat = (dateString: string) => {
  return `${format(
    new Date(dateString),
    "'On' do LLLL, yyyy 'at' hh':'mm aa"
  )}`;
};

export const receiptDateTimeFormat = (dateString: string) => {
  return `${format(new Date(dateString), "MM/dd/yyyy hh:mm:ss aa")}`;
};

export enum DateOptions {
  CURRENT = "current",
  PREVIOUS = "Previous",
  CUSTOM = "custom",
}

export const createFilterDateOption = (
  type: DateOptions,
  date?: Date | string
) => {
  let response = {
    minDate: "",
    maxDate: "",
  };
  const currentDateStart = new Date();
  currentDateStart.setHours(0, 0, 0, 0);
  const previousDateStart = new Date();
  previousDateStart.setHours(-24, 0, 0, 0);

  switch (type) {
    case DateOptions.CURRENT:
      response.minDate = currentDateStart.toISOString();
      response.maxDate = new Date().toISOString();
      break;
    case DateOptions.PREVIOUS:
      response.minDate = previousDateStart.toISOString();
      response.maxDate = currentDateStart.toISOString();
      break;
    case DateOptions.CUSTOM:
      if (date) {
        const startdate = new Date(date);
        startdate.setHours(0, 0, 0, 0);
        const endDate = new Date(date);
        endDate.setHours(24, 0, 0, 0);
        response.minDate = startdate.toISOString();
        response.maxDate = endDate.toISOString();
      }
      break;
    default:
      break;
  }

  return response;
};

/* Generate Ref
 * Rules are:
 * The first digit, "O"should always be zero (0) this indicates that the payment made was in cash.
 * "061" indicates the date the payment was made: Fixed 3 digits that are the Julian date. In the example number above, "061" is the Julian date as March 1st (leap, 2012-03-01).
 * "55" Reflects the amount of the payment, excluding cents: In the example, the customer paid $55.97, so there is a 55 listed in this position.
 * "O" Every receipt will have a zero in this position.
 * "IL448" displays the Store ID: Fixed 5 digits that list the Agent Store ID. In the example above, "IL448" is the Agent Store.
 * "7" The last number reflects the last number of the MDN or FA of the customer. In the example, the customer's MDN was xxxx-xxxx-2297.
 */

export const gererateRef = (
  date: Date,
  amount: number,
  agent: string,
  account: string
) => {
  return `0${getDayOfYear(new Date(date))
    .toString()
    .padStart(3, "0")}${Math.floor(amount)}0${agent}${account.slice(-1)}`;
};
