import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";

import { RootState } from "store";
import { GenericStateType, defaultGenericState } from "store/types";
import { fetchOperators } from "./operator-action";
import { UserAccount } from "store/user/user";

interface OperatorState extends GenericStateType {}

const operatorEntityAdapter = createEntityAdapter<UserAccount>({});

const operatorSlice = createSlice({
  name: "operators",
  initialState: operatorEntityAdapter.getInitialState<OperatorState>({
    ...defaultGenericState,
  }),
  reducers: {
    resetOperators(state) {
      operatorEntityAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchOperators.fulfilled,
      (state, action: PayloadAction<UserAccount[]>) => {
        state.status = "idle";
        state.isSuccess = true;
        state.isLoading = false;
        operatorEntityAdapter.addMany(state, action.payload);
      }
    );
    builder.addCase(fetchOperators.pending, (state) => {
      state.isLoading = true;
      state.status = "inProcess";
    });
    builder.addCase(fetchOperators.rejected, (state) => {
      state.isLoading = false;
      state.status = "idle";
    });
  },
});

export const {
  selectAll: selectAllOperators,
  selectById: selectOperatorById,
  selectIds: selectOperatorIds,
} = operatorEntityAdapter.getSelectors((state: RootState) => state.operator);

export const { resetOperators } = operatorSlice.actions;
export default operatorSlice.reducer;
