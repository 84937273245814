import { PaymentActivity } from "store/paymentActivity/paymentActivity-slice";

export const groupPaymentsByConfirmationNumber = (data: PaymentActivity[]) => {
  const resultTemp: PaymentActivity[] = data.reduce(
    (accRes: any, payment: PaymentActivity) => {
      const key = payment.confirmationNumber;
      if (!!accRes[key]) {
        accRes[key].amountPaid += payment.amountPaid;
        accRes[key].linkedData.push(payment);
      } else {
        accRes[key] = { ...payment, linkedData: [payment] };
      }
      return accRes;
    },
    {}
  );

  return resultTemp ? Object.values(resultTemp) : [];
};
