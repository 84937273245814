import { AxiosInstance } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { FetchPayloadResult } from "./paymentActivity-slice";
import { FT_PAYMENT_ACTIVITY, FT_REQUEST_BASE_URL } from "constants/request";

export type FetchPayments = typeof fetchPayments;

export interface FetchPaymentActivityProps {
  sortColumn?: number;
  sortDir?: number;
  minDate?: string;
  maxDate?: string;
  paymentMethodType?: number;
  paymentMethodName?: number;
  status?: number[];
  pageNumber?: number;
  pageSize?: number;
  confirmationNumber?: string;
  forPending?: boolean;
  accountNumber?: string;
  userId?: number;
}

export const constructPaymentActivityUrl: any = (
  {
    minDate,
    maxDate,
    paymentMethodType,
    status,
    pageNumber,
    pageSize,
    sortColumn,
    sortDir,
    confirmationNumber,
    forPending,
    accountNumber,
    userId,
  }: Partial<FetchPaymentActivityProps>,
  paymentActivityUrl: URL
) => {
  (status || []).forEach((paymentActivityStatus) => {
    paymentActivityUrl.searchParams.append(
      "status",
      paymentActivityStatus.toString()
    );
  });

  if (!!minDate) {
    paymentActivityUrl.searchParams.append("minDate", minDate);
  }
  if (!!maxDate) {
    paymentActivityUrl.searchParams.append("MaxDate", maxDate);
  }
  if (!!confirmationNumber) {
    paymentActivityUrl.searchParams.append(
      "confirmationNumber",
      confirmationNumber
    );
  }
  if (paymentMethodType !== undefined && paymentMethodType !== null) {
    paymentActivityUrl.searchParams.append(
      "PaymentMethodType",
      paymentMethodType.toString()
    );
  }
  if (!!accountNumber) {
    paymentActivityUrl.searchParams.append("accountNumber", accountNumber);
  }
  if (pageSize !== undefined && pageSize !== null) {
    paymentActivityUrl.searchParams.append("PageSize", pageSize.toString());
  } else {
    paymentActivityUrl.searchParams.append("PageSize", pageSize || "1000");
  }
  if (pageNumber !== undefined && pageNumber !== null) {
    paymentActivityUrl.searchParams.append("PageNum", pageNumber.toString());
  } else {
    paymentActivityUrl.searchParams.append("PageNum", "1");
  }
  if (sortColumn !== undefined && sortColumn !== null) {
    paymentActivityUrl.searchParams.append("SortColumn", sortColumn.toString());
  }
  if (sortDir !== undefined && sortDir !== null) {
    paymentActivityUrl.searchParams.append("SortDir", sortDir.toString());
  }
  if (userId !== undefined && userId !== null) {
    paymentActivityUrl.searchParams.append("userId", userId.toString());
  }
  return paymentActivityUrl;
};

export const fetchPayments = createAsyncThunk<
  FetchPayloadResult,
  {
    request: AxiosInstance;
    data: FetchPaymentActivityProps;
  }
>("paymentActivity/fetchPayments", async ({ request, data }) => {
  let paymentActivityUrl = new URL(FT_PAYMENT_ACTIVITY, FT_REQUEST_BASE_URL);
  paymentActivityUrl = constructPaymentActivityUrl(data, paymentActivityUrl);

  const response = await request(paymentActivityUrl.toString(), {
    method: "GET",
  });

  return response.data;
});

export type DeletePayment = typeof deletePayment;

export type DeletePaymentDataProps = {
  id: number;
  reversalCodeId: number;
};

export const deletePayment = createAsyncThunk<
  any,
  {
    request: AxiosInstance;
    data: DeletePaymentDataProps;
  }
>("paymentActivity/deletePayment", async ({ request, data }) => {
  await request(`${FT_PAYMENT_ACTIVITY}/cancel`, {
    method: "PUT",
    data
  });
  return data.id;
});
