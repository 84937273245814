import { SliderPageWrapper } from "../../layout/sliderPageWrapper/SliderPageWrapper";

import classes from "./Admin.module.scss";

const admin = [
  { label: "User Management", id: "user_management" },
  { label: "Printer Settings", id: "printer_settings" },
];

export const Admin = () => {
  return (
    <SliderPageWrapper headerTitle="Admin">
      <section className={classes.Container}>
        <ul>
          {admin.map((single, i) => (
            <li key={i}>{single.label}</li>
          ))}
        </ul>
      </section>
    </SliderPageWrapper>
  );
};
