import { useAssets } from "hooks";
import { useRootSelector } from "store";

import classes from "./InnerContentLayout.module.scss";

export interface InnerContentLayoutProps {
  children: React.ReactNode;
}

export const InnerContentLayout: React.FC<InnerContentLayoutProps> = ({
  children,
}) => {
  const { createAssetUrl } = useAssets();
  const { client } = useRootSelector((state) => state.config);

  return (
    <article className={classes.Wrapper}>
      <section className={classes.HeadWrapper}>
        <p>
          <img
            className={classes.Logo}
            src={createAssetUrl("logo")}
            alt={client.defaultApplicationName}
          />
        </p>
      </section>
      <section className={classes.Container}>{children}</section>
    </article>
  );
};
