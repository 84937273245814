import {
  Auth0Provider,
  AppState,
  Auth0ProviderOptions,
} from "@auth0/auth0-react";
import { defaultScope } from "constants/idp-scopes";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useRootSelector } from "store";

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate: React.FC<
  Auth0ProviderWithNavigateProps
> = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();
  const { client } = useRootSelector((state) => state.config);

  const onRedirectCallback = (appState?: AppState) => {
    let to = appState?.returnTo;
    if (!to && !!client && !!client.customerKey) {
      to = `/${client.customerKey}/dashboard`;
    } else {
      to = window.location.pathname;
    }

    navigate(to);
  };

  if (!!client && !!client.idPId) {
    if (!client.customerKey) {
      console.warn("No available customer key for the application.", client);
      localStorage.removeItem("lastSavedKey");
      sessionStorage.removeItem("persist:root");
      if (!!window.dtrum) {
        window.dtrum.reportCustomError(
          "no-customer-key",
          `The client ${client.idPId} has no customer key.`,
          "auth0-proivder-with-navigate.tsx"
        );
      }
      window.location.reload();
    }
    const redirectUri = new URL(
      `/${client.customerKey}`,
      window.location.toString()
    );

    const providerConfig: Auth0ProviderOptions = {
      domain: process.env.REACT_APP_AUTH0_DOMAIN!,
      clientId: client.idPId,
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      cacheLocation: "localstorage",
      authorizationParams: {
        connection: process.env.REACT_APP_AUTH0_CONNECTION, // TODO: This needs to be configurable per the client
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
          ? process.env.REACT_APP_AUTH0_AUDIENCE
          : "",
        redirect_uri: redirectUri.toString(),
        scope: defaultScope,
      },
      onRedirectCallback,
    };

    return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
  } else {
    return <>{children}</>;
  }
};
