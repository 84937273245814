import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRootSelector } from "store";

interface ErrorHandlerProps {
  children: React.ReactNode;
}

export const ErrorHandler: React.FC<ErrorHandlerProps> = ({
  children,
}: PropsWithChildren<ErrorHandlerProps>): JSX.Element | null => {
  const { error } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { client } = useRootSelector((state) => state.config);

  useEffect(() => {
    if (!!error) {
      const errorMessage = `Error: ${searchParams.get(
        "error"
      )} - Description: ${searchParams.get("error_description")}`;

      if (window.dtrum) {
        window.dtrum.reportCustomError(
          "IdP Error",
          errorMessage,
          "auth0-provider-with-navigate.tsx"
        );
      }

      console.error(error);

      if (!location.pathname.startsWith(`/${client.customerKey}/error`)) {
        navigate({
          pathname: `/${client.customerKey}/error`,
          search: `?${searchParams.toString()}`,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, searchParams]);

  return <>{children}</>;
};
