import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { SerializedError, createAsyncThunk } from "@reduxjs/toolkit";

import { AccountLookup } from "./accountLookup-slice";
import { FT_ACCOUNT_LOOKUP } from "constants/request";

export type FetchAccount = typeof fetchAccount;
export type FetchAccountData = {
  accountLookupProps: Omit<AccountLookup, "id">;
  clientId: number;
};

export const fetchAccount = createAsyncThunk<
  AccountLookup,
  {
    request: AxiosInstance;
    data: FetchAccountData;
  },
  { rejectValue: AxiosResponse<SerializedError> }
>("accountLookup/fetchAccont", async ({ request, data }, thunkAPI) => {
  const reqDataObj = [
    {
      trackingProperty: {
        displayName: "AccountOrWirelessNumber",
        propertyName: "AccountOrWirelessNumber",
        isInvoiceProperty: true,
        isCustomProperty: true,
        providedForValidation: true,
      },
      value:
        data.accountLookupProps.accountNumber || data.accountLookupProps.wireNo,
    },
  ];
  try {
    const response = await request(FT_ACCOUNT_LOOKUP, {
      method: "POST",
      data: reqDataObj,
      params: {
        clientId: data.clientId,
      },
    });
    return {
      ...response.data[0],
    };
  } catch (err) {
    return thunkAPI.rejectWithValue(((err as AxiosError<SerializedError>).response as AxiosResponse<SerializedError>));
  }
});
