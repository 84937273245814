import { AxiosInstance } from "axios";

import * as requestUrl from "../../constants/request";

import { Configuration, createDefaultConfig } from "./configuration";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const fetchConfig = createAsyncThunk(
  "config/fetchConfig",
  async ({ request, key }: { request: AxiosInstance; key: string }) => {
    const paymentCenterConfigResponse = await request(
      requestUrl.FT_PAYMENT_CENTER_CONFIG
    );

    const defaultConfig = createDefaultConfig(key, null);
    const config: Configuration = {
      ...defaultConfig,
      client: {
        ...defaultConfig.client,
      },
      paymentCenter: {
        ...defaultConfig.paymentCenter,
      },
      ...paymentCenterConfigResponse.data,
    };
    if (config.client.idPId) {
      localStorage.setItem(key, config.client.idPId);
      localStorage.setItem("lastSavedKey", key);
    }

    return config;
  }
);
