import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { defaultGenericState } from "store/types";

import { AgentDTO } from "./agent";
import { fetchAgent } from "./agent-action";
import { agentEntityAdapter } from "./agent-adpater";
import { AgentState } from "./agent-state";

const agentSlice = createSlice({
  name: "agents",
  initialState: agentEntityAdapter.getInitialState<AgentState>({
    ...defaultGenericState,
  }),
  reducers: {
    resetOperators(state) {
      agentEntityAdapter.removeAll(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchAgent.fulfilled,
      (state, action: PayloadAction<AgentDTO>) => {
        state.status = "idle";
        state.isSuccess = true;
        state.isLoading = false;
        agentEntityAdapter.addOne(state, action.payload);
      }
    );
    builder.addCase(fetchAgent.pending, (state) => {
      state.isLoading = true;
      state.status = "inProcess";
    });
    builder.addCase(fetchAgent.rejected, (state) => {
      state.isLoading = false;
      state.status = "idle";
    });
  },
});

export const { resetOperators } = agentSlice.actions;
export default agentSlice.reducer;
