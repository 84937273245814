import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classes from "./Dialog.module.scss";
import { ReactPortal } from "../portal";

export interface DialogProps {
  dialogClosed?: (close: boolean) => void;
  closeButtonHandler?: () => void;
  primaryActionLabel?: string;
  secondaryActionLabel?: string;
  primaryActionHandler?: () => void;
  secondaryActionHandler?: () => void;
  hideIndication?: boolean;
  hideActions?: boolean;
  showSecondaryAction?: boolean;
  // Set this property to "false" if you do not want the dialog to autoclose when the primary action handler is clicked
  autoClose?: boolean;
  type?: "error" | "default" | "warning";
  icon?: string;
  closeIcon?: boolean;
  scrimClickAction?: boolean;
  translatedMessage?: string;
  children?: React.ReactNode;
  overflowVisible?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  dialogClosed,
  primaryActionLabel,
  secondaryActionLabel,
  primaryActionHandler,
  hideIndication,
  hideActions,
  autoClose = true,
  type = "default",
  icon,
  closeIcon,
  closeButtonHandler,
  scrimClickAction,
  showSecondaryAction = false,
  translatedMessage,
  secondaryActionHandler,
  overflowVisible,
  ...props
}) => {
  const dialog: any = useRef<HTMLElement>(null);
  const { t } = useTranslation(["common"]);
  useEffect(() => {
    const dialogRef = dialog.current;
    if (dialogRef) {
      dialogRef.addEventListener("closed", (e: any) => {
        if (dialogClosed && e.detail) dialogClosed(false);
      });
    }
    return () => {
      if (dialogRef) {
        dialogRef.addEventListener("closed", (e: any) => {
          if (dialogClosed && e.detail) dialogClosed(false);
        });
      }
    };
  }, [dialogClosed]);

  const actionHandler = () => {
    if (primaryActionHandler) primaryActionHandler();
  };

  const iconWrapperClasses = [classes.Icon_wrapper];
  const iconClasses = [classes.Icon];
  const buttonClasses = [];
  if (type === "warning") {
    iconWrapperClasses.push(classes.Warning);
    iconClasses.push(classes.Warning);
    buttonClasses.push(classes.Warning);
  }

  const closeDialog = () => {
    if (dialog.current) dialog.current.removeAttribute("open");
  };

  //overflowVisible

  const wrapperClass = [classes.Container];
  if (overflowVisible) {
    wrapperClass.push(classes.OverflowVisible);
  }

  return (
    <ReactPortal>
      <ft-dialog
        hideActions
        open
        ref={dialog}
        scrimClickAction={!!scrimClickAction ? "" : true}
        heading={translatedMessage ? translatedMessage : props.children}
      >
        <div className={wrapperClass.join(" ")}>
          {closeIcon ? (
            <span className={classes.CloseButton}>
              <ft-icon onClick={closeDialog}>close</ft-icon>
            </span>
          ) : null}

          {hideIndication ? null : (
            <div className={classes.Indication}>
              <div className={iconWrapperClasses.join(" ")}></div>
              <ft-icon class={iconClasses.join(" ")}>
                {icon ? icon : "warning"}
              </ft-icon>
            </div>
          )}
          <div role="document" className={classes.Contents}>
            {translatedMessage ? translatedMessage : props.children}
          </div>
          {hideActions ? null : (
            <div className={classes.Action_buttons}>
              {!!showSecondaryAction ? (
                <ft-button
                  class={["outline-button", classes.Cancel].join(" ")}
                  dialogAction="cancel"
                  outlined
                  onClick={
                    secondaryActionHandler ? secondaryActionHandler : null
                  }
                >
                  {secondaryActionLabel
                    ? secondaryActionLabel
                    : t("cancel_label")}
                </ft-button>
              ) : null}
              {autoClose ? (
                <ft-button
                  onClick={actionHandler}
                  dialogAction="discard"
                  unelevated
                  class={buttonClasses.join(" ")}
                >
                  {primaryActionLabel ? primaryActionLabel : t("okay_label")}
                </ft-button>
              ) : (
                <ft-button
                  onClick={actionHandler}
                  unelevated
                  class={buttonClasses.join(" ")}
                >
                  {primaryActionLabel ? primaryActionLabel : t("okay_label")}
                </ft-button>
              )}
            </div>
          )}
        </div>
      </ft-dialog>
    </ReactPortal>
  );
};
