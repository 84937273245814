import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { RootState } from "store";

import { defaultGenericState } from "store/types";
import { fetchEndOfDayTransmit, makePayment } from "./paymentDetails-action";
import { PaymentActivity } from "store/paymentActivity/paymentActivity-slice";

export interface Payment {
  paymentAmount: number;
  customerName: string;
  id: string;
  accountNumber: string;
  paymentMethodType: number;
  accountInformationId: string;
}

export interface EoDTO {
  timeOfLastTransaction: string;
  daysCashAmount: string;
}

interface PaymentDetailsState {
  isLoading: boolean;
  isSuccess?: boolean | undefined;
  error: any;
  status: "idle" | "inProcess" | "error" | "success";
  paymentMeta?: PaymentActivity[];
  eod?: EoDTO;
}

const paymentDetailsEntityAdapter = createEntityAdapter<Payment>({});

const PaymentDetailsSlice = createSlice({
  name: "paymentDetail",
  initialState:
    paymentDetailsEntityAdapter.getInitialState<PaymentDetailsState>({
      ...defaultGenericState,
    }),
  reducers: {
    setPaymentValue(state, action: PayloadAction<Payment>) {
      paymentDetailsEntityAdapter.upsertOne(state, action.payload);
    },
    deletePayment(state, action: PayloadAction<string>) {
      paymentDetailsEntityAdapter.removeOne(state, action.payload);
    },
    resertPayments(state) {
      paymentDetailsEntityAdapter.removeAll(state);
      state.paymentMeta = undefined;
      state.status = "idle";
    },
    resertEod(state) {
      state.eod = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      makePayment.fulfilled,
      (state, action: PayloadAction<PaymentActivity[]>) => {
        state.isLoading = false;
        state.status = "success";
        state.paymentMeta = action.payload;
      }
    );
    builder.addCase(makePayment.pending, (state) => {
      state.isLoading = true;
      state.status = "inProcess";
    });
    builder.addCase(makePayment.rejected, (state) => {
      state.isLoading = false;
      state.status = "error";
    });
    builder.addCase(
      fetchEndOfDayTransmit.fulfilled,
      (state, action: PayloadAction<EoDTO>) => {
        state.eod = action.payload;
      }
    );
  },
});

export const {
  selectAll: selectAllPayments,
  selectById: selectPaymentById,
  selectIds: selectPaymentIds,
} = paymentDetailsEntityAdapter.getSelectors(
  (state: RootState) => state.paymentDetails
);

export const { setPaymentValue, resertPayments, deletePayment, resertEod } =
  PaymentDetailsSlice.actions;
export default PaymentDetailsSlice.reducer;
