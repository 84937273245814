import { PURGE } from "redux-persist";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { FtTableHeadData } from "components/common/table/FtTable";
import { AccountInfo } from "store/account/account-slice";

export interface FilterProps {
  startDate: string | null;
  endDate: string | null;
  searchKeyword: string;
  dueDate: number;
  pastDue: number;
  dueIn5days: number;
  autoPay: number;
}

export interface AccountSummary {
  head?: FtTableHeadData[];
  results?: AccountInfo[];
  pageNum?: number;
  totalCount?: number;
  sortColumn?: number;
  sortDir?: number;
  isLoading?: boolean;
  fetchingData?: boolean;
}

export interface QuickPayState {
  filters: FilterProps;
  accountSummary: AccountSummary;
}

const initialFilters: FilterProps = {
  startDate: null,
  endDate: null,
  searchKeyword: "",
  pastDue: 0,
  dueDate: 0,
  autoPay: 0,
  dueIn5days: 0,
};

const initialState: QuickPayState = {
  filters: initialFilters,
  accountSummary: {
    results: [],
    isLoading: false,
  },
};

const accountSummaryAdapter = createEntityAdapter<QuickPayState>({});

export const accountSummarySlice = createSlice({
  name: "account-summary",
  initialState: accountSummaryAdapter.getInitialState(initialState),
  reducers: {
    setFilters(state, action: PayloadAction<FilterProps>) {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clearFilter(state) {
      state.filters = initialFilters;
    },
    setAccountSummaryData(state, action: PayloadAction<AccountSummary>) {
      state.accountSummary = action.payload;
    },
    setAccountSummaryIsLoading(
      state,
      action: PayloadAction<{
        isLoading: boolean;
      }>
    ) {
      state.accountSummary.isLoading = action.payload.isLoading;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      accountSummaryAdapter.removeAll(state);
    });
  },
});

export const {
  setFilters,
  clearFilter,
  setAccountSummaryData,
  setAccountSummaryIsLoading,
} = accountSummarySlice.actions;
export default accountSummarySlice.reducer;
