import { ClientConfiguration } from "./client-configuration";
import { PaymentCenterConfiguration } from "./payment-center-configuration";

export interface Configuration {
  client: ClientConfiguration;
  paymentCenter: PaymentCenterConfiguration;
}

export function createDefaultConfig(
  customerKey: string | null,
  idPId: string | null
): Configuration {
  return {
    client: {
      clientId: null,
      customerKey,
      idPId,
      translationsProjectId: process.env.FT_ROOT_TRANSLATIONS_PROJECT_ID!,
    },
    paymentCenter: {
      clientId: null,
      customerKey,
      idPId,
      accountTypes: {},
      defaultFees: {},
      quickPayments: {
        enabled: false,
      },
      widgets: {
        editingEnabled: false,
      },
      quickPayTrackingProperties: [],
      registrationTrackingProperties: [],
    },
  };
}
