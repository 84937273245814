import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { PaymentCenterInformation, UserAccount } from "./user";

interface UserState extends UserFirsTechInfo, UserAuthInfo {

}

export interface UserFirsTechInfo {
  user?: UserAccount | null;
  paymentCenterInfo?: PaymentCenterInformation | null;
  isLoading?: boolean;
  fetchingData?: boolean;
  userResponse?: any;
}

export interface UserAuthInfo {
  roles: string[];
  claims: { [claimName: string]: any }
}

const initialState: UserState = {
  roles: [],
  claims: {}
};

const userAdapter = createEntityAdapter<UserState>({});

export const userSlice = createSlice({
  name: "user",
  initialState: userAdapter.getInitialState(initialState),
  reducers: {
    setUser(state, action: PayloadAction<UserAccount>) {
      state.user = {
        ...state.user,
        ...action.payload,
      };
    },
    setPaymentCenter(state, action: PayloadAction<PaymentCenterInformation>) {
      state.paymentCenterInfo = {
        ...state.paymentCenterInfo,
        ...action.payload,
      };
    },
    updateUserEmail(state, action: PayloadAction<string>) {
      if (action.payload && state.user?.email) {
        state.user.email = action.payload;
      }
    },
    resetUser(state) {
      state.user = null;
      state.paymentCenterInfo = null;
    },
    updateUserState(state, action: PayloadAction<UserFirsTechInfo>) {
      if (action.payload.user) {
        state.user = {
          ...state.user,
          ...action.payload.user,
        };
      }
      if (typeof action.payload.userResponse !== "undefined") {
        state.userResponse = action.payload.userResponse;
      }
      if (typeof action.payload.isLoading === "boolean") {
        state.isLoading = action.payload.isLoading;
      }
      if (typeof action.payload.fetchingData === "boolean") {
        state.fetchingData = action.payload.fetchingData;
      }
    },
    updateAuthInfo(state, action: PayloadAction<UserAuthInfo>) {
      
      state.claims = action.payload.claims;
      state.roles = action.payload.roles;
    },
    setIsLoading(
      state,
      action: PayloadAction<{
        isLoading: boolean;
        fetchingData: boolean;
      }>
    ) {
      state.isLoading = action.payload.isLoading;
      state.fetchingData = action.payload.fetchingData;
    },
  },
});

export const {
  setIsLoading,
  setPaymentCenter,
  setUser,
  updateUserState,
  updateUserEmail,
  updateAuthInfo,
  resetUser,
} = userSlice.actions;
export default userSlice.reducer;
