import { FT_ASSET_REQUEST_PATH } from "constants/request";
import { useCallback } from "react";
import { useRootSelector } from "store/hooks";

export const useAssets = () => {
  const { client } = useRootSelector((state) => state.config);

  const createAssetUrl = useCallback(
    (assetName: string) => {
      if (client.idPId) {
        return `${FT_ASSET_REQUEST_PATH}${client.idPId}/${assetName}`;
      } else {
        return "";
      }
    },
    [client.idPId]
  );

  const createDefaultAssetUrl = useCallback((assetName: string) => {
    return `${FT_ASSET_REQUEST_PATH}default/${assetName}`;
  }, []);

  return {
    createAssetUrl,
    createDefaultAssetUrl,
  };
};
