import { Suspense, memo } from "react";

import { useRootSelector } from "store/hooks";

import { ComingSoon } from "components/sliderPage/comingSoon/ComingSoon";
import { Payments } from "components/sliderPage/payments/Payments";
import { Admin } from "components/sliderPage/admin/Admin";

//export const promptedRoute = ["MakeAPayment"];
export const promptedRoute = [];

const SliderLoader = () => (
  <>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "65px", width: "100%" })}
      count={1}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "105px", width: "100%" })}
      count={1}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "40px", width: "50%" })}
      count={1}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "20px", width: "100%" })}
      count={5}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "40px", width: "50%" })}
      count={1}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "20px", width: "100%" })}
      count={5}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "40px", width: "50%" })}
      count={1}
    ></ft-loader>
    <ft-loader
      styleOverrides={JSON.stringify({ height: "20px", width: "100%" })}
      count={5}
    ></ft-loader>
  </>
);

export const SlideRoute = memo(() => {
  const pageDetail = useRootSelector((state) => state.common.pageDetail);

  let page = null;

  if (pageDetail) {
    switch (pageDetail.id) {
      case "payments":
        page = <Payments />;
        break;
      case "admin":
        page = <Admin />;
        break;
      default:
        page = <ComingSoon />;
    }
  }

  return <Suspense fallback={<SliderLoader />}>{page}</Suspense>;
});
